<!-- 
    Esta é a página de Relatórios de Vendas, que utiliza o layout do painel em: 
    "./layouts/Dashboard.vue".
 -->

 <template>
    <div>

        <a-row type="flex" :gutter="24">

            <!-- Coluna de Informações de Vendas -->
            <a-col :span="24" :md="24">
                <a-row type="flex" :gutter="24">
                    <a-col :span="12" :xl="6" class="mb-24" v-for="(revenue, index) in revenues" :key="index">

                        <!-- Widget de Receita -->
                        <WidgetRevenue
                            :value="revenue.value"
                            :prefix="revenue.prefix"
                            :icon="revenue.icon"
                            :title="revenue.title"
                            :content="revenue.content"
                        ></WidgetRevenue>
                        <!-- / Widget de Receita -->

                    </a-col>
                </a-row>
            </a-col>
            <!-- / Coluna de Informações de Vendas -->
            
            <!-- Coluna de Faturas de Vendas -->
            <!-- <a-col :span="24" :md="24" class="mb-24">

                <CardProductInvoices
                    :data="salesInvoiceData"
                ></CardProductInvoices>

            </a-col> -->
            <!-- / Coluna de Faturas de Vendas -->

        </a-row>

        <!-- <a-row type="flex" :gutter="24">

            <a-col :span="24" :md="16" class="mb-24">

                <CardSalesInfo></CardSalesInfo>

            </a-col>

            <a-col :span="24" :md="8" class="mb-24">

                <CardStudentEngagement
                    :data="studentEngagementData"
                ></CardStudentEngagement>

            </a-col>
            
        </a-row> -->

    </div>
</template>

<script>

    import CardProductSales from "../../components/Cards/CardCredit"
    import WidgetRevenue from "../../components/Widgets/WidgetSalary"
    import CardAbandonedSales from "../../components/Cards/CardPaymentMethods"
    import CardProductInvoices from "../../components/Cards/CardInvoices"
    import CardSalesInfo from "../../components/Cards/CardBillingInfo"
    import CardStudentEngagement from "../../components/Cards/CardTransactions"


    // Dados dos cartões de receita
    const revenues = [
        {
            value: 0,
            prefix: "+R$",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"/></svg>`,
            title: "Receita de Produtos",
            content: "Receita total de vendas de conteúdos",
        },
        {
            value: 0,
            prefix: "+R$",
            icon: `
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>`,
            title: "Vendas Abandonadas",
            content: "Compras não finalizadas ou canceladas",
        },
        {
            value: 0,
            prefix: "+R$",
            icon: `
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V192c0-35.3-28.7-64-64-64H80c-8.8 0-16-7.2-16-16s7.2-16 16-16H448c17.7 0 32-14.3 32-32s-14.3-32-32-32H64zM416 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
            title: "Saldo a receber",
            content: "Relatório de saldos",
        },
        {
            value: 0,
            prefix: "+R$",
            icon: `
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffffff" d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/></svg>`,
            title: "Perfil Demográfico dos Clientes",
            content: "Idade dos Compradores, Distribuição Geográfica, Segmentação por Genero",
        },
    ];

    // Dados da lista de "Faturas de Vendas".
    const salesInvoiceData = [
        {
            title: "1 de março de 2021",
            code: "#MS-415646",
            amount: "180",
        },
        {
            title: "12 de fevereiro de 2021",
            code: "#RV-126749",
            amount: "250",
        },
        {
            title: "5 de abril de 2020",
            code: "#FB-212562",
            amount: "550",
        },
        {
            title: "25 de junho de 2019",
            code: "#QW-103578",
            amount: "400",
        },
        {
            title: "3 de março de 2019",
            code: "#AR-803481",
            amount: "700",
        },
        {
            title: "21 de fevereiro de 2019",
            code: "#AR-803412",
            amount: "100",
        },
    ];

    // Dados da lista de "Engajamento de Alunos".
    const studentEngagementData = [
        {
            title: "João Silva",
            activity: "Curso Concluído: Estratégias Avançadas de Marketing",
            date: "27 de março de 2021",
        },
        {
            title: "Maria Souza",
            activity: "Curso Iniciado: Maestria em SEO",
            date: "26 de março de 2021",
        },
        // Adicione mais dados relevantes de engajamento de alunos
    ];

    export default ({
        components: {
            CardProductSales,
            WidgetRevenue,
            CardAbandonedSales,
            CardProductInvoices,
            CardSalesInfo,
            CardStudentEngagement,
        },
        data() {
            return {
                // Dados dos cartões de receita
                revenues,

                // Associando dados da lista de "Faturas de Vendas" com sua propriedade correspondente.
                salesInvoiceData,
                
                // Associando dados da lista de "Engajamento de Alunos" com sua propriedade correspondente.
                studentEngagementData,
            }
        },
    })

</script>

<style lang="scss">
</style>
